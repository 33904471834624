import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import duotone from "../images/DuotoneLiam.png"

const PersonalImage = () => (
  <StyledSection>
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 50 }}
      transition={{ delay: 0.2 }}
    >
      <StyledDiv>
        <img src={duotone} alt="Duotone Headshot of Liam Hawks" />
      </StyledDiv>
    </motion.div>
  </StyledSection>
)

const StyledDiv = styled.div`
  background: linear-gradient(
    230deg,
    ${props => props.theme.backgroundAccent},
    ${props => props.theme.main}
  );
  height: 60vw;
  width: 60vw;
  min-width: 15rem;
  min-height: 15rem;
  max-width: 22rem;
  max-height: 22rem;
  border-radius: 50%;
`

const StyledSection = styled.section`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: ${props => props.theme.breakpoint}) {
    margin-left: 1rem;
  }
`

export default PersonalImage
