import React from "react"
import styled from "styled-components"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"

const Project = ({ title, images, description }) => (
  <InView>
    {({ inView, ref }) => (
      <StyledDiv ref={ref}>
        <motion.div
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
          transition={{ delay: 0.2 }}
        >
          <StyledFigure>
            <PhoneGraphic>
              <img src={images[0].src} alt={images[0].alt} />
            </PhoneGraphic>
            <DescriptionDiv>
              <h3>{title}</h3>
              {description}
            </DescriptionDiv>
          </StyledFigure>
        </motion.div>
      </StyledDiv>
    )}
  </InView>
)

const StyledDiv = styled.div`
  background: linear-gradient(
    270deg,
    ${props => props.theme.backgroundAccent},
    ${props => props.theme.main}
  );
  background-size: 200%;
  color: ${props => props.theme.background};
  border-radius: 2rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
`

const PhoneGraphic = styled.figure`
  width: 264px;
  height: 533px;
  padding: 8px;
  border-radius: 36px;
  background: #f6f9fc;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  flex: 0 1 264px;
`

const DescriptionDiv = styled.div`
  flex: 1 1 350px;
  margin: 0 1rem 0 2rem;
`

const StyledFigure = styled.figure`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;

  & h3 {
    color: ${props => props.theme.foreground};
    text-align: center;
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0.05rem 0.05rem 0 ${props => props.theme.background},
      0.1rem 0.1rem 0 ${props => props.theme.background};
    margin: 2rem 0 1rem 0;
  }

  & p {
    margin-bottom: 0.5rem;
  }

  & p:last-child {
    margin-bottom: 0;
  }
`

export default Project
