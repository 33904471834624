import React, { useState } from "react"
import Layout from "../components/layout/Layout"
import Head from "../components/layout/Head"
import styled from "styled-components"
import { motion } from "framer-motion"
import Showcase from "../components/projects/Showcase"
import PersonalImage from "../components/PersonalImage"
import SectionTitle from "../components/SectionTitle"
import { graphql } from "gatsby"

export const query = graphql`
  {
    siteBuildMetadata {
      buildTime
    }
  }
`

const IndexPage = ({ data }) => {
  const [displayInfo, setDisplayInfo] = useState(false)
  return (
    <>
      <Head title="Liam Hawks: Full-stack JavaScript Developer" />
      <Layout>
        <Title>Liam Hawks</Title>
        <Blurb>Full-stack JavaScript Developer</Blurb>
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
          <AboutSection id="about">
            <PersonalImage />
            <DescriptionSection>
              <p>
                I'm a developer with over 10 years of experience focused on
                building products via JavaScript using Gatsby, Next.js &amp;
                React.
              </p>
              <p>
                I'm based near Bristol, UK (UTC+0) but usually work remotely.
                I'm open to both contracts and full-time roles right now so feel
                free to get in touch.
              </p>
              <p>
                It's been a varied journey for me. From my early days as a Flash
                Developer and some time where I ran kitchens as a chef. My love
                of figuring problems out and coding creatively has stayed
                constant!
              </p>
            </DescriptionSection>
          </AboutSection>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
          animate={{ opacity: 1 }}
        >
          <Showcase id="showcase" />
        </motion.div>
        <StyledSection id="skills">
          <SectionTitle>Skills</SectionTitle>
          <ul>
            <li>
              Building with <Skill>JavaScript,</Skill> <Skill>HTML</Skill> &amp;{" "}
              <Skill>CSS</Skill>
            </li>
            <li>
              Making apps or sites with <Skill>React,</Skill>{" "}
              <Skill>Next.js</Skill> &amp; <Skill>Gatsby</Skill>
            </li>
            <li>
              Creating <Skill>Node.js</Skill> servers, tools and packages
            </li>
            <li>
              Top-notch <Skill>Project Management</Skill> &amp;{" "}
              <Skill>Communication</Skill>
            </li>
            <li>
              Designing via Adobe <Skill>Photoshop,</Skill>{" "}
              <Skill>Illustrator</Skill> &amp; <Skill>XD</Skill>
            </li>
            <li>
              Utilising <Skill>Git</Skill> &amp; 'nix{" "}
              <Skill>Command Line</Skill>
            </li>
            <li>
              Styling using <Skill>Sass</Skill> &amp;{" "}
              <Skill>Styled Components</Skill>
            </li>
            <li>
              Deploying with <Skill>Netlify</Skill> &amp; <Skill>Heroku</Skill>
            </li>
            <li>
              Handling <Skill>Stripe,</Skill> <Skill>AWS</Skill> &amp;{" "}
              <Skill>Firebase</Skill>
            </li>
            <li>
              Testing with <Skill>Jest,</Skill> <Skill>Cypress</Skill> &amp;{" "}
              <Skill>Lighthouse CI</Skill>
            </li>
          </ul>
        </StyledSection>

        <StyledSection>
          <SectionTitle>Contact Me</SectionTitle>
          <p>
            <StyledLink
              href="https://www.linkedin.com/in/liam-hawks-developer/"
              alt="Liam Hawks LinkedIn Profile"
            >
              LinkedIn
            </StyledLink>
            <br />
            <StyledLink
              href="https://www.github.com/hawkstein/"
              alt="Liam Hawks GitHub Account"
            >
              GitHub
            </StyledLink>
            <br />
            <StyledLink
              href="https://twitter.com/liamhawks"
              alt="Liam Hawks Twitter Account"
            >
              Twitter
            </StyledLink>
            <br />
            <StyledLink href="/LiamHawksCVMarch2021.pdf" alt="CV / Resume PDF">
              CV/Resume
            </StyledLink>
          </p>
          <div>
            {displayInfo ? (
              <>
                <p>
                  Email:{" "}
                  <StyledLink
                    href="mailto:contact@liamhawks.dev"
                    alt="Liam Hawks' Email"
                  >
                    contact@liamhawks.dev
                  </StyledLink>
                  <br />
                  Phone:{" "}
                  <StyledLink
                    href="tel:07549495785"
                    alt="Liam Hawks' Phone Number"
                  >
                    07549-495-785
                  </StyledLink>
                </p>
              </>
            ) : (
              <ContactButton
                onClick={() => {
                  setDisplayInfo(true)
                }}
              >
                Click for contact info
              </ContactButton>
            )}
          </div>
          <p>
            Want me to build something for you?
            <br />
            Check out{" "}
            <StyledLink
              href="https://www.stylishmethod.com"
              alt="Stylish Method"
            >
              Stylish Method
            </StyledLink>
          </p>
          <p>This site was built using Gatsby {`<3`}.</p>
          <p>
            Site last updated:{" "}
            {new Date(data.siteBuildMetadata.buildTime).toLocaleString("en-GB")}
          </p>
        </StyledSection>
      </Layout>
    </>
  )
}

const Title = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  margin: 1rem 0 0 0;
  color: ${props => props.theme.main};
  background: linear-gradient(
    230deg,
    ${props => props.theme.backgroundAccent},
    ${props => props.theme.main}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
`
const Blurb = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0 0 1rem 0;
  color: ${props => props.theme.foreground};
  @media (min-width: ${props => props.theme.breakpoint}) {
    margin-bottom: 2rem;
  }
`

const Skill = styled.span`
  color: ${props => props.theme.background};
  padding: 0.1rem;
  font-weight: 700;
  background-color: ${props => props.theme.foreground};
`

const ContactButton = styled.button`
  background-color: ${props => props.theme.main};
  border: none;
  padding: 0.5rem;
  color: ${props => props.theme.background};
  font-weight: 700;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.foreground};
  }
`

const AboutSection = styled.section`
  margin-bottom: 3rem;
  @media (min-width: ${props => props.theme.breakpoint}) {
    display: flex;
    flex-direction: row-reverse;
    max-width: ${props => `calc(${props.theme.breakpoint} - 10rem)`};
    align-items: center;
  }
`

const DescriptionSection = styled.section`
  & p {
    margin-bottom: 1rem;
  }
`

const StyledLink = styled.a`
  color: ${props => props.theme.main};
  text-decoration: none;
  font-weight: 600;
  position: relative;

  &:hover {
    color: ${props => props.theme.background};
  }

  &:hover::before {
    -webkit-transform: skewX(0deg) scaleY(1) rotate(0deg);
    transform: skewX(0deg) scaleY(1) rotate(0deg);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    width: calc(100% + 10px);
    height: 1.4rem;
    background: ${props => props.theme.main};
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: skewX(0deg) scaleY(0) rotate(10deg);
    transform: skewX(0deg) scaleY(0) rotate(10deg);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: -1;
  }
`

const StyledSection = styled.section`
  margin-top: 3rem;
`

export default IndexPage
