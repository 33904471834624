import React from "react"
import styled from "styled-components"
import ProjectList from "../projects/ProjectList"
import Project from "../projects/Project"
import babImage from "../../images/bab-mobile-home.png"
import diskoImage from "../../images/disko-act.png"
import qsImage from "../../images/qs-landing.png"
import SectionTitle from "../SectionTitle"

const StyledSection = styled.section``
const StyledLiveLink = styled.a`
  color: ${props => props.theme.foreground};
  background: ${props => props.theme.background};
  text-decoration: none;
  font-weight: 600;
  padding: 0.3rem;
  border-radius: 3px;
`
const StyledP = styled.p`
  margin-top: 1rem;
`

const babProject = {
  title: "Beelzebab",
  images: [{ src: babImage, alt: "Beelzebab Mobile Homepage" }],
  description: (
    <div>
      <p>
        Beelzebab, a beloved and unique kitchen, wanted to help customers find
        restaurant info quickly and improve the experience of buying merch.
      </p>
      <p>
        I built an accessible lightning fast site with great SEO and custom
        store that staff can update stock for easily.
      </p>
      <p>
        Now existing and potential customers find what they need quickly and
        merch sales are kept on brand.
      </p>
      <p>
        Stack used: <strong>Gatsby</strong>, <strong>Stripe</strong> &amp;{" "}
        <strong>Firebase</strong>
      </p>
      <StyledP>
        <StyledLiveLink href="https://www.beelzebab.com">
          Check out the live site
        </StyledLiveLink>
      </StyledP>
    </div>
  ),
}
const quickProject = {
  title: "Quick Stock",
  images: [{ src: qsImage, alt: "Quickstock Signup Page" }],
  description: (
    <div>
      <p>
        Most inventory and organisation apps for professional kitchens are
        bloated with unused features, slow and clunky.
      </p>
      <p>
        I developed a lean inventory tool for chefs managing small to medium
        kitchens.
      </p>
      <p>
        It saves users time, money, makes shifts easier and helps their
        customers have a better experience.
      </p>
      <p>
        Stack used: <strong>Next.js</strong>, <strong>FaunaDB (GraphQL)</strong>
        , &amp; <strong>Stripe</strong>
      </p>
    </div>
  ),
}
const diskoProject = {
  title: "Diskalendar",
  images: [{ src: diskoImage, alt: "Act Detail/Information Page" }],
  description: (
    <div>
      <p>
        Music festival and arts events need simple calendars for customers that
        will function well in adverse conditions.
      </p>
      <p>
        I built a lightweight resilient PWA timetable for Goulash Disko music
        festival to help attendees find artists and plan their time.
      </p>
      <p>
        Now guests at the festival, even in bad network conditions, can figure
        out where to head to for their best experience possible.
      </p>
      <p>
        This project used my open source Gatsby Theme 'gatsby-theme-schedule'.
      </p>
      <p>
        Stack used: <strong>Gatsby &amp; GraphQL</strong>
      </p>
      <StyledP>
        <StyledLiveLink href="https://www.github.com/hawkstein/gatsby-theme-schedule">
          See code and sites on Github
        </StyledLiveLink>
      </StyledP>
    </div>
  ),
}

const projects = [babProject, quickProject, diskoProject]

const Showcase = ({ id }) => (
  <StyledSection id={id}>
    <SectionTitle>Latest Projects</SectionTitle>
    <ProjectList>
      {projects.map(proj => (
        <Project
          title={proj.title}
          images={proj.images}
          description={proj.description}
        />
      ))}
    </ProjectList>
  </StyledSection>
)

export default Showcase
