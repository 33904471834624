import React from "react"
import styled from "styled-components"

const ProjectList = ({ children }) => <StyledDiv>{children}</StyledDiv>

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export default ProjectList
