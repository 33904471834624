import React from "react"
import styled from "styled-components"

const SectionTitle = ({ children }) => <StyledHeader>{children}</StyledHeader>

const StyledHeader = styled.h3`
  color: ${props => props.theme.background};
  font-family: ${props => props.theme.titleFont};
  display: inline-block;
  padding: 0.8rem;
  font-size: 2.3rem;
  position: relative;
  border-radius: 0.2rem;
  background: linear-gradient(
    230deg,
    ${props => props.theme.backgroundAccent},
    ${props => props.theme.main}
  );
`

export default SectionTitle
